import React from 'react';
import I18n from '../utils/I18n';

const ArchitectReviewBanner = ({lang}) => {
  const i18n = new I18n({ lang });
  return (
    <div className="cs-section--hero2 slds-is-relative">
    	<div className="cs-breadcrumb">
    		<a href="/credentials/administratoroverview">{i18n.text("breadcrumb_root")}</a> &gt; {i18n.text("architectReviewBoards_title")}
    	</div>
    	<div className="reviewboard-hero-title">
    		<div>
    			<h1 className="cs-section--hero-title slds-container--center slds-text-align--center Ff(NeutrafaceDisplay-Titling)">
    				{i18n.text("architectReviewBoards_title")}
    			</h1>

    			<div className="slds-container--center slds-container--medium slds-text-align--center slds-m-top--medium Fz(18px) Lh(1.5)">
            {i18n.text("architectReviewBoards_subtitle")}
    			</div>
    		</div>
    	</div>
    </div>
  );
};

export default ArchitectReviewBanner;
